import React, {useEffect, useState, useContext} from "react";
import {ContainerListaClausulas} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import TableAllClausulas from "../../components/TableAllClausulas/TableAllClausulas";
import ModalCreateClausula from "../../components/ModalCreateClausula/ModalCreateClausula";
import {modal} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";

const ListaClausulas = () => {
  var columns = ["Nome", ""];
  const [openModal, setOpenModal] = useState(false);
  const [clausulas, setClausulas] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  useEffect(() => {
    document.title = `BUDGET | Cláusulas`;
    sendMessageWS({method: "list_clausulas"});
  }, []);

  useEffect(() => {
    if (message && message.method == "list_clausulas" && message.data !== null) {
      setClausulas(message.data.clausulas);
    }
    if (message && message.method == "update_clausula" && message.data !== null) {
      setClausulas(message.data.clausulas);
    }
  }, [message]);

  useEffect(() => {
    setFiltered(clausulas);
  }, [clausulas]);

  var originalData = [...clausulas];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString) ) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <ContainerListaClausulas>
      <HeaderGeral
        titulo={""}
        subtitulo={"Cláusulas"}
        btnCta={"Nova cláusula"}
        placeholder={"Pesquise uma clásula..."}
        handleClickCta={e => {
          modal.open(setOpenModal);
        }}
        agency={false}
        year={false}
        search={true}
        budget={true}
        handleSearch={handleSearch}
        openModal={openModal}
        setOpenModal={setOpenModal}

      />

      <TableAllClausulas columns={columns} rows={filtered} listdata={filtered} />

      <ModalCreateClausula
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        modalTitle={"Nova Cláusula"}
      />
    </ContainerListaClausulas>
  );
};

export default ListaClausulas;

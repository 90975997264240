import React, {useEffect, useState, useContext} from "react";
import {ContainerListaServicos} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import TableServicos from "../../components/TableAllServices/TableAllServices";
import {Button} from "@mui/material";
import ModalCreateService from "../../components/ModalCreateService/ModalCreateService";
import {modal} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";
import makeRequest from "../../../utils/makeRequest";
import getCsrfToken from "../../../utils/getCsrfToken";
import getCookie from "../../../utils/GetCookie";

const ListaServicos = () => {
  var columns = ["Nome", "Valor", ""];
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [services, setServices] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  useEffect(() => {
    document.title = `BUDGET | Serviços`;
    sendMessageWS({method: "list_services", data: {agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")}});
  }, []);

  useEffect(() => {
    if (message && message.method == "list_services" && message.data !== null) {
      setServices(message.data.services);
    }
    if (message && message.method == "update_service" && message.data !== null) {
      setServices(message.data.services);
    }
  }, [message]);

  useEffect(() => {
    setFiltered(services);
  }, [services]);

  const handleSendFile = () => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/upload_services/`;

    let formData = new FormData();
    formData.append("role_file", file);
    formData.append("agency_uuid", getCookie("agencyuuid") && getCookie("agencyuuid"));
    // formData.append("agency_uuid", window.sessionStorage.getItem("ag-id") && window.sessionStorage.getItem("ag-id"));

    getCsrfToken().then(res => {
      makeRequest(url, formData, "post", "", res.data).then(response => {
        if (response.status == 200) {
          sendMessageWS({method: "list_services"});
          setOpenModal(false);
        }
      });
    });
  };

  var originalData = [...services];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString) || b.area__name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <ContainerListaServicos>
      <HeaderGeral
        file={file}
        setFile={setFile}
        handleSendFile={handleSendFile}
        titulo={""}
        subtitulo={"Serviços"}
        btnCta={"Novo serviço"}
        placeholder={"Pesquise um serviço..."}
        handleClickCta={e => {
          modal.open(setOpenModal);
        }}
        planilhaCta={true}
        agency={false}
        year={false}
        search={true}
        budget={true}
        handleSearch={handleSearch}
        openModal={openModal}
        setOpenModal={setOpenModal}
        
      />

      <TableServicos columns={columns} rows={filtered} listdata={filtered} />

      <ModalCreateService
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        modalTitle={"Novo Serviço"}
      />
    </ContainerListaServicos>
  );
};

export default ListaServicos;
